import { Box, Container, Grid, Typography } from '@mui/material';
import podLogo from 'assets/images/pod-logo.svg';
import registerIllustration from 'assets/images/registerIllustration.svg';
import { MetaTags } from 'components/common';
import Link from 'components/Link';

import { Login } from 'components/v2/Login';
import { ToastContainer } from 'react-toastify';

const LoginPage = () => {
  return (
    <>
      <MetaTags
        title="Login | Podworld"
        description="Log In and Invest as low as Rs 20,000 also get access to the leading online marketplace for Startups and Investors to connect and Own a piece of the next big startup."
        imageUrl={`${process.env.NEXT_PUBLIC_FRONTEND_URL}pod-logo.png`}
        url={process.env.NEXT_PUBLIC_FRONTEND_URL!}
        keywords="POD login"
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flexGrow={1}
        bgcolor="#F3F9EC"
      >
        <ToastContainer />
        <Container maxWidth="lg">
          <Grid container>
            <Grid
              item
              md={6.5}
              sx={{
                display: {
                  xs: 'none',
                  md: 'block',
                },
              }}
            >
              <Box
                sx={{
                  '& img': {
                    maxWidth: '100%',
                    maxHeight: '100%',
                  },
                }}
              >
                <img src={registerIllustration} alt="" />
              </Box>
            </Grid>
            <Grid item md={5.5} xs={12}>
              <Box
                sx={{
                  pl: {
                    xs: 'inherit',
                    md: '110px',
                  },
                }}
              >
                <Box
                  display="flex"
                  sx={{
                    '& a': {
                      mr: 2,
                    },
                  }}
                >
                  <Link href="/faqs" noLinkStyle>
                    <Typography>FAQs</Typography>
                  </Link>
                  <Link href="/pod-cafe" noLinkStyle>
                    <Typography>POD Cafe</Typography>
                  </Link>
                  <Link href="/about" noLinkStyle>
                    <Typography>About</Typography>
                  </Link>
                </Box>
                <Box mt={7}>
                  <img src={podLogo} alt="pod logo" />
                </Box>
                <Login />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default LoginPage;
