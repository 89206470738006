import { Box, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { PasswordInput, TextInput } from '../common/TextInput';
import emailIcon from 'assets/images/email.svg';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BigButton } from '../common/Buttons/BigButton';
import GoogleLogin from 'react-google-login';
import { CustomButton } from 'components/common';
import GoogleImage from 'assets/images/googleImg.png';
import { ApiPostNoAuth } from 'helpers/Api/ApiData';
import Auth from 'helpers/auth';
import { toast, ToastContainer } from 'react-toastify';
import { useRouter } from 'next/router';
import { useUser } from 'context/user.context';
import Link from 'components/Link';
import topRightArrow from 'assets/images/topRightArrow.svg';
import { useRedirectUrl } from 'context/redirectUrl.context';
import { useEffect } from 'react';

interface Inputs {
  email: string;
  password: string;
}

const schema: yup.SchemaOf<Inputs> = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email address')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

export const Login = () => {
  const [redirectUrl] = useRedirectUrl();
  const [userData, , setUserData] = useUser();
  const router = useRouter();

  // console.log(router.asPath);

  useEffect(() => {
    if (userData.user?.role_id) {
      if (userData.user.role_id == 1) {
        router.push(redirectUrl || '/startups');
      } else if (userData.user.role_id == 2) {
        router.push(redirectUrl || '/create-startup-profile');
      }
    }
  }, [userData.user?.role_id, redirectUrl, router]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const res: any = await ApiPostNoAuth(`user/login`, { ...data });
      if (res.data.data !== null && res.data.statusCode === 200) {
        // setLoader(false);
        const data = res.data.data;
        Auth.setAuthToken(data.token);
        if (data.user.role_id === 1) {
          setUserData({ user: res.data?.data?.user, isLoading: false });

          // if (router.query.redirect_url) {
          // if (redirectUrl) {
          //   router.push(redirectUrl);
          // } else {
          //   router.push('/startups');
          // }
        } else if (data.user.role_id === 2) {
          setUserData({ user: res.data?.data?.user, isLoading: false });

          // if (redirectUrl) {
          //   router.push(redirectUrl);
          // } else {
          //   router.push('/create-startup-profile');
          // }
        } else {
          toast.success('Admin cannot login', {
            autoClose: 5000,
            hideProgressBar: true,
          });
        }
      } else {
        toast.error(res.data.message, {
          autoClose: 5000,
          hideProgressBar: true,
        });
        // setLoader(false);
      }
    } catch (err: any) {
      console.log('err --------> ', err);
      toast.error(err.message, { autoClose: 5000, hideProgressBar: true });
      // setLoader(false);
    }
  };

  const onGoogleSuccess = async (res: any) => {
    // console.log('Login Success: currentUser: %j', res.profileObj);
    onSubmitGoogleRegister(res.profileObj);
  };

  const onSubmitGoogleRegister = async (data: any) => {
    try {
      delete data?.googleId;
      const res: any = await ApiPostNoAuth(`user/socialLogin`, {
        ...data,
        type: 'login',
      });
      if (res.data.data !== null && res.data.statusCode === 200) {
        const data = res.data.data;
        Auth.setAuthToken(data.token);
        if (data.user.role_id === 1) {
          setUserData({ user: res.data?.data?.user, isLoading: false });
          // if (redirectUrl) {
          //   router.push(redirectUrl);
          // } else {
          //   router.push('/startups');
          // }
        } else if (data.user.role_id === 2) {
          Auth.setUserData(res.data?.data?.user);
          setUserData({ user: res.data?.data?.user, isLoading: false });

          // if (redirectUrl) {
          //   router.push(redirectUrl);
          // } else {
          //   router.push('/create-startup-profile');
          // }
        } else {
          toast.success('Admin cannot login', {
            autoClose: 5000,
            hideProgressBar: true,
          });
        }
      } else {
        toast.error(res.data.message, {
          autoClose: 5000,
          hideProgressBar: true,
        });
        // setLoader(false);
      }
    } catch (error: any) {
      console.log(error);
      toast.error(error.message, { autoClose: 5000, hideProgressBar: true });
      // setLoader(false);
    }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });
  return (
    <Box component="form">
      <ToastContainer />
      <Typography
        component="h1"
        fontWeight={700}
        fontSize={28}
        lineHeight="32.81px"
        mt="42px"
      >
        Login
      </Typography>
      <Typography
        mt={1.5}
        fontWeight={400}
        fontSize={14}
        lineHeight="21px"
        color="rgba(0, 0, 0, 0.67)"
      >
        Login to your POD account using your registered email & password.
      </Typography>
      <TextInput
        sx={{ mt: 3 }}
        placeholder="Email"
        icon={emailIcon}
        register={register('email')}
        errorMessage={errors.email?.message}
      />

      <PasswordInput
        sx={{ mt: 1.5 }}
        placeholder="Password"
        type="password"
        register={register('password')}
        errorMessage={errors.password?.message}
        autoComplete="password"
      />

      {/* <Box display="flex" justifyContent="flex-end">
        <FormControlLabel
          sx={{
            mr: 0,
            mt: '-12px',
            '& .MuiFormControlLabel-label ': {
              color: '#646464',
              fontWeight: 400,
              fontSize: 16,
            },
          }}
          value="remember-me"
          control={
            <Checkbox
              disableRipple
              sx={{
                color: '#42526E',
                '&.Mui-checked': {
                  color: '#42526E',
                },
              }}
            />
          }
          label="Remember me"
          labelPlacement="end"
        />
      </Box> */}
      <Link
        href="/forgot-password"
        noLinkStyle
        sx={{ color: '#000F0A', fontWeight: 500, fontSize: 14 }}
      >
        Forgot Password?
      </Link>
      <BigButton
        onClick={handleSubmit(onSubmit)}
        type="submit"
        sx={{ py: '10px', width: '100%', mt: 1.5 }}
      >
        <Typography fontWeight={700} fontSize={18}>
          LOG IN
        </Typography>
      </BigButton>
      <Typography
        component="p"
        fontSize={14}
        lineHeight="16.41px"
        align="center"
        mt="30px"
        mb={3}
      >
        Or
      </Typography>
      <GoogleLogin
        clientId={
          '335830995858-tbi0m6n9fmf8q4nlrr5o062ebg7a8vpq.apps.googleusercontent.com'
        }
        render={(renderProps) => (
          <CustomButton
            type="button"
            sx={{
              fontSize: 20,
              width: 280,
              height: 50,
              mt: 2,
              mb: 3,
              mx: 'auto',
              background: '#4285F4',
              '&:hover': {
                backgroundColor: '#4285F4',
              },
              display: 'flex',
              pl: 0,
            }}
            onClick={() => {
              renderProps.onClick();
            }}
            disabled={renderProps.disabled}
            // className="googleSignup"
          >
            <Box
              // className="googleIcon"
              component="small"
              flexShrink={0}
              sx={{
                // display: 'flex',
                alignItems: 'center',
                backgroundColor: '#fff',
                borderRadius: 50,
                height: '40px',
                width: '40px',
                p: 0.5,
                // mr: '40px',
                '& img': {
                  width: '100%',
                  height: '100%',
                  m: 'auto',
                },
                ml: '6px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {' '}
              <img src={GoogleImage} alt="" />
            </Box>
            <Box ml={1} flexGrow={1}>
              Login with Google
            </Box>
          </CustomButton>
        )}
        buttonText=" Sign up with Google"
        onSuccess={onGoogleSuccess}
        // onFailure={onGoogleFailure}
        cookiePolicy={'single_host_origin'}
        style={{ marginTop: '100px' }}
        // isSignedIn={true}
      />
      <Typography
        align="center"
        color="rgba(0, 0, 0, 0.6)"
        fontSize={14}
        fontWeight={400}
      >
        Don&apos;t have account?{' '}
        <Link
          noLinkStyle
          href="/register"
          sx={{ fontWeight: 700, color: '#111' }}
        >
          Sign up
        </Link>
      </Typography>
      <Box mt={5} borderTop="1px solid #D9D9D9" />

      <Typography
        color="rgba(0, 0, 0, 0.67)"
        fontSize={10}
        sx={{
          '& img': {
            mx: 0.5,
          },
        }}
        lineHeight="24px"
      >
        By logging in to your POD account, you agree to the{' '}
        <span>
          <Link href="/terms-service" display="flex" noLinkStyle>
            <Typography
              fontSize={10}
              component="span"
              fontWeight={700}
              color="#111"
            >
              Terms and Conditions
            </Typography>
            <img src={topRightArrow} alt="link" />
          </Link>
        </span>{' '}
        and{' '}
        <Link href="/privacy-policy" display="flex" noLinkStyle>
          <Typography
            component="span"
            fontSize={10}
            fontWeight={700}
            color="#111"
          >
            Privacy Policy
          </Typography>
          <img src={topRightArrow} alt="link" />
        </Link>
      </Typography>
    </Box>
  );
};
